import React, { useState, useEffect } from "react";
import "./JoinUs.scss";
import joinUsImage from "../../images/انضم إلينا.jpg";
import { useForm } from "react-hook-form";

const JoinUs = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [successMessage, setSuccessMessage] = useState("");
  const [joinImage, setJoinImage] = useState("");

  useEffect(() => {
    const fetchJoinImage = async () => {
      try {
        const response = await fetch(
          `https://admin.internationalskillsacademy.com/api/website_settings/`
        );
        if (response.ok) {
          const data = await response.json();
          const joinImageUrl = data.results[0].join_image;
          if (joinImageUrl) {
            setJoinImage(joinImageUrl);
          } else {
            console.error("لا توجد صورة للانضمام");
          }
        }
      } catch (error) {
        console.error("خطأ في جلب البيانات:", error);
      }
    };

    fetchJoinImage();
  }, []);

  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append("name", data.name);
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("phone2", data.phone2);
    formData.append("gender", data.gender);
    formData.append("birthdate", data.birth_date);
    formData.append("country", data.country);
    formData.append("degree", data.degree);
    formData.append("currentJob", data.currentJob);
    formData.append("trainingHours", data.trainingHours);
    formData.append("experienceYears", data.experienceYears);
    formData.append("specialties", data.specialties);
    formData.append("facebookLink", data.facebookLink);
    formData.append("linkedinLink", data.linkedinLink);
    formData.append("CV", data.cv[0]);
    formData.append("photo", data.photo[0]);
    formData.append("additionalInfo", data.additionalInfo);

    try {
      const response = await fetch(
        `https://admin.internationalskillsacademy.com/api/join/`,
        {
          method: "POST",
          body: formData,
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log("Success:", result);

        setSuccessMessage("تم إرسال طلبك بنجاح!");

        reset();
      } else {
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div className="join-us">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 mb-5 mb-lg-0 pe-lg-5">
            {joinImage && (
              <img className="img-fluid" src={joinImage} alt="انضم إلينا" />
            )}

            <p>
              إن أكاديمية ISA للتدريب يشرفها دائما أن تتعاون مع أقوى المحاضرين،
              وأشهر المدربين في مختلف المجالات والتخصصات.
              <br />
              ويشرفنا دائما طلبات السادة المدربين والمحاضرين للانضمام للعمل معنا
              يدا واحدة لتحقيق ذات الهدف، هو السعي الدؤوب لتنمية الشباب العربي،
              والمساعدة في تأهيل المتدربين إلى سوق العمل، وفتح آفاق جديدة لهم عن
              طريق تصميم وتطوير أحدث الحقائب التدريبية التي تتناسب مع متطلبات
              سوق العمل المتجددة دائما.
              <br />
              كذلك العمل معا لتوفير أفضل تقنيات التدريب في العالم.
            </p>

            <p>
              ولأجل ذلك نحن نسعى دائما وبشكل مستمر لضم كل الكفاءات الراغبة في
              العمل معنا لتحقيق تلك الأهداف، وللانضمام إلى فريق المحاضرين
              والمدربين المتعاونين معنا، فإنه يجب أن يتوافر في المُحاضر الراغب
              في التعاون معنا بعض المعايير الأساسية، ونستعرضها كالآتي:
            </p>

            <ul>
              <li>
                الدرجة العلمية لا تقل عن الماجستير في التخصص المطلوب الانضمام
                له.
              </li>
              <li>
                أن يكون المحاضر له عدد ساعات تدريبية لا تقل عن 2000 ساعة تدريبية
                في ذات التخصص.
              </li>
              <li>
                القدرة على الاعتماد في التدريب على التطبيقات العملية ودراسات
                الحالة والوقائع والخبرات الفعلية خلال تقديم الشرح، ليكون
                البرنامج التدريبي فريدا من حيث الأسلوب والمحتوى، ولا يقتصر على
                المادة العلمية النظرية فقط، وإنما مدعوما بكل ما هو عملي ومتطلب
                حقيقي من متطلبات سوق العمل.
              </li>
              <li>سابقة أعمال تدريبية مع مراكز مشهود لها بالكفاءة والجودة.</li>
              <li>
                السيرة الذاتية الغنية بالخبرات في ذات المجال، التي تدعم خبرات
                المحاضر لسنوات طويلة في ذات التخصص.
              </li>
              <li>
                السمات الشخصية والمهارات اللازمة للتدريس ونقل الخبرات والمعارف،
                حيث يجب على المحاضر أن يكون ملما بأحدث أساليب التدريب والشرح
                والتدريس ولديه المهارات الواجبة لنقل العلوم والخبرات إلى
                المتدربين بكافة المستويات، بحيث يمتلك القدرة على التبسيط غير
                المُخل والتدرج في الشرح للوصول إلى أعلى مستوى من الخبرة بكل
                سلاسة، بما يجعله قادرا على التعاطي مع جميع المتدربين الحاضرين
                بشكل مميز وفعال.
              </li>
            </ul>

            <p>
              لكل من يجد في نفسه امتلاك تلك المعايير الأساسية والضرورية، ولديه
              الرغبة في الانضمام للتعاون معنا، فنحن يشرفنا دائما وأبدا أن نضم
              إلى فريقنا كل الخبرات والكفاءات في جميع التخصصات.
              <br />
              فقط كل ما عليك هو ملئ النموذج التالي، وستقوم إدارة التدريب
              بالأكاديمية بمراجعة تلك المعلومات بشكل متكامل، وفي حال تطابق تلك
              المعلومات مع المعايير التي تضعها الأكاديمية، سيقوم أحد المسؤولين
              المختصين بالتواصل معك لتحديد موعدا مناسبا للاجتماع والنقاش حول
              التعاون بينك وبين الأكاديمية.
            </p>
          </div>
          <div className="col-lg-6 ps-lg-5">
            <div className="px-lg-3">
              <h3>طلب الانضمام الى فريق المُحاضرين المتعاونين مع الأكاديمية</h3>
              <p>
                إن أكاديمية ISA للتدريب يشرفها دائما ان تتعاون مع أقوى المحاضرين
                وأشهر المدربين...
              </p>
              <form onSubmit={handleSubmit(onSubmit)} className="mb-1">
                <div className="row">
                  <div className="form-group col-12">
                    <label> الاسم بالكامل : </label>
                    <input
                      className="form-control"
                      {...register("name", { required: true })}
                      placeholder="اسمك"
                    />
                    {errors.name && <span className="error">الاسم مطلوب</span>}
                  </div>
                  <div className="form-group col-12">
                    <label> البريد الالكتروني : </label>
                    <input
                      className="form-control"
                      {...register("email", { required: true })}
                      placeholder="ايميلك"
                    />
                    {errors.email && (
                      <span className="error">الايميل مطلوب</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> رقم الموبايل/الواتساب : </label>
                    <input
                      className="form-control"
                      {...register("phone", { required: true })}
                      placeholder="رقم الموبايل/الواتساب"
                    />
                    {errors.phone && (
                      <span className="error">رقم الموبايل مطلوب</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> رقم موبايل اضافي : </label>
                    <input
                      className="form-control"
                      {...register("phone2")}
                      placeholder="رقم موبايل اضافي"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label> مدرب أم مدربة : </label>
                    <select
                      className="form-control"
                      {...register("gender", { required: true })}
                    >
                      <option value="">اختر...</option>
                      <option value="male">مدرب</option>
                      <option value="female">مدربة</option>
                    </select>
                    {errors.gender && (
                      <span className="error">يجب اختيار الجنس</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> تاريخ الميلاد : </label>
                    <input
                      type="date"
                      className="form-control"
                      {...register("birthdate", { required: true })}
                    />
                    {errors.birthdate && (
                      <span className="error">تاريخ الميلاد مطلوب</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> الدولة : </label>
                    <input
                      className="form-control"
                      {...register("country", { required: true })}
                      placeholder="الدولة"
                    />
                    {errors.country && (
                      <span className="error">الدولة مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> الدرجة العلمية : </label>
                    <input
                      className="form-control"
                      {...register("degree", { required: true })}
                      placeholder="الدرجة العلمية"
                    />
                    {errors.degree && (
                      <span className="error">الدرجة العلمية مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> الوظيفة الحالية : </label>
                    <input
                      className="form-control"
                      {...register("currentJob", { required: true })}
                      placeholder="الوظيفة الحالية"
                    />
                    {errors.currentJob && (
                      <span className="error">الوظيفة الحالية مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> عدد الساعات التدريبية التي قمت بتنفيذها : </label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("trainingHours", { required: true })}
                      placeholder="عدد الساعات التدريبة"
                    />
                    {errors.trainingHours && (
                      <span className="error">
                        عدد الساعات التدريبية المطلوبة
                      </span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> سنوات الخبرة في مجالك : </label>
                    <input
                      type="number"
                      className="form-control"
                      {...register("experienceYears", { required: true })}
                      placeholder="سنوات الخبرة"
                    />
                    {errors.experienceYears && (
                      <span className="error">سنوات الخبرة مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> التخصصات التي تقدم فيها التدريب : </label>
                    <input
                      className="form-control"
                      {...register("specialties", { required: true })}
                      placeholder="التخصصات"
                    />
                    {errors.specialties && (
                      <span className="error">التخصصات مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> رابط صفحة الفيسبوك : </label>
                    <input
                      className="form-control"
                      {...register("facebookLink")}
                      placeholder="رابط صفحة الفيسبوك"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label> رابط صفحة لينكد إن : </label>
                    <input
                      className="form-control"
                      {...register("linkedinLink")}
                      placeholder="رابط صفحة لينكد إن"
                    />
                  </div>
                  <div className="form-group col-12">
                    <label> السيرة الذاتية : </label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("Cv", { required: true })}
                    />
                    {errors.Cv && (
                      <span className="error">السيرة الذاتية مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label> صورة شخصية : </label>
                    <input
                      type="file"
                      className="form-control"
                      {...register("photo", { required: true })}
                    />
                    {errors.photo && (
                      <span className="error">الصورة الشخصية مطلوبة</span>
                    )}
                  </div>
                  <div className="form-group col-12">
                    <label>
                      {" "}
                      معلومات اضافية (جوائز، لقاءات تلفزيونية، مؤتمرات، ...) :{" "}
                    </label>
                    <textarea
                      className="form-control"
                      {...register("additionalInfo")}
                      placeholder="معلومات اضافية"
                    />
                  </div>
                </div>
                <button type="submit" className="btn-black">
                  أرسل طلبك
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default JoinUs;
