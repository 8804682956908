import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "./ProgramCourses.scss";

const ProgramCourses = ({ match }) => {
  const [courses, setCourses] = useState([]);
  const programId = match.params.programId;

  useEffect(() => {
    const fetchCourses = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/courses/?program__id=${programId}`
        );
        setCourses(response.data.results);
      } catch (error) {
        console.error("Error fetching courses:", error);
      }
    };

    fetchCourses();
  }, [programId]);

  return (
    <div className="program-courses">
      <h2>الدورات المتعلقة بالبرنامج</h2>
      <div className="courses-list">
        {courses.map((course) => (
          <Link
            to={`/course/${course.id}`}
            key={course.id}
            className="course-item"
          >
            <img src={course.image} alt={course.title} />
            <h4>{course.title}</h4>
            <p>{course.summary}</p>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default ProgramCourses;
