import React, { useState, useEffect } from "react";
import "./CHL-Section.scss";
import logo from "../../images/CHL-Logo.jpg";
import { Link } from "react-router-dom";

const CHL_Section = () => {
  const [isPopupOpen, setPopupOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const togglePopup = () => {
    setPopupOpen(!isPopupOpen);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/pages/"
        );
        const data = await response.json();

        const image = data.results[1]?.image;
        if (image) {
          setImageSrc(image);
        }
      } catch (error) {
        console.error("Error fetching data", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="chl-section">
      <div className="container">
        <div className="header-text text-center">
          <h3>أكاديمية CHL للمونتيسوري</h3>
          <p>هي أكاديمية منبثقة من أكاديمية ISA</p>
        </div>
      </div>
      <div>
        {imageSrc && (
          <Link to="/chl-page">
            <img
              src={imageSrc}
              alt="partner"
              className="partner-logo"
              // onClick={() => (window.location.href = "/chl-page")}
              style={{ cursor: "pointer" }}
            />
          </Link>
        )}
      </div>

      {isPopupOpen && (
        <div className="popup-overlay" onClick={togglePopup}>
          <div className="popup-content" onClick={(e) => e.stopPropagation()}>
            <h4>خدماتنا الاستشارية</h4>
            <p>نقدم لكم خدمة الاستشارات الأسرية و التربوية</p>
            <ul>
              <li>
                المشكلات الدراسية (التركيز - الانتباه - ضعف التحصيل الدراسي)
              </li>
              <li>
                المشكلات السيكلوجية (العصبية - العناد - الحساسية - وغيرها)
              </li>
              <li>صعوبات التعلم</li>
              <li>تعديل السلوك</li>
              <li>
                الإرشاد الأسري (للأمهات والآباء) من خلال جلسات البيلوثرابيا
              </li>
            </ul>
            <p>
              لحجز المواعيد والاستفسار عن الجلسات:{" "}
              <strong>963933954480+</strong>
            </p>
            <button onClick={togglePopup}>إغلاق</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CHL_Section;
