import React, { useState, useEffect } from "react";
import "./CertificatesSection.scss";
import axios from "axios";
import cert1 from "../../images/certificate.jpg";
import { useHistory } from "react-router-dom";

const CertificatesSection = () => {
  const [programs, setPrograms] = useState([]);

  const history = useHistory();

  useEffect(() => {
    const fetchPrograms = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/programs/`
        );
        setPrograms(response.data.results);
      } catch (error) {
        console.error("Error fetching programs:", error);
      }
    };

    fetchPrograms();
  }, []);

  const handleProgramClick = (programId) => {
    history.push(`/program-courses/${programId}`);
  };

  return (
    <div className="certificates-section">
      <div className="overlay"></div>
      <div className="container">
        <div className="header-text text-center">
          <h3>أنواع الشهادات التي نقدمها</h3>
          <p>احصل على شهادات معترف بها دوليًا لتعزيز مسيرتك المهنية.</p>
        </div>
        <div className="certificates-list">
          {programs.map((program, index) => (
            <div
              key={program.index}
              className="certificate-item"
              onClick={() => handleProgramClick(program.id)}
            >
              <img src={program.image || cert1} alt={`شهادة ${index + 1}`} />
              <h4>{program.title}</h4>
              <p>{program.summary}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default CertificatesSection;
