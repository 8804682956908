import React, { useEffect, useState } from "react";
import "./ConsultingAndStudies.scss";
import exampleImage from "../../images/about-us.jpeg";

const ConsultingAndStudies = () => {
  const [studiesData, setStudiesData] = useState({
    studies: "",
    studies_image: "",
  });

  useEffect(() => {
    const fetchStudiesData = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/website_settings/"
        );
        const data = await response.json();

        if (data.results.length > 0) {
          const result = data.results[0];
          setStudiesData({
            studies: result.studies,
            studies_image: result.studies_image,
          });
        }
      } catch (error) {
        console.error("Error fetching studies data:", error);
      }
    };

    fetchStudiesData();
  }, []);

  const renderStudies = () => {
    const lines = studiesData.studies.split("\n");
    return (
      <div style={{ lineHeight: 2.5, marginTop: "30px" }}>
        <p>{lines[0]}</p>
        <ul>
          {lines.slice(1).map((line, index) => {
            if (line) {
              return <li key={index}>{line.replace("•", "").trim()}</li>;
            }
            return null;
          })}
        </ul>
      </div>
    );
  };

  return (
    <div className="consulting-and-studies">
      <div className="content">
        <div className="text-content">
          <h3>الاستشارات و الدراسات</h3>
          {/* {renderStudies()} */}
          <p dangerouslySetInnerHTML={{ __html: studiesData.studies }} />
        </div>
        <div className="image-content">
          <img src={studiesData.studies_image} alt="Consulting and Studies" />
        </div>
      </div>
    </div>
  );
};

export default ConsultingAndStudies;
