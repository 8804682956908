import "./Regulations.scss";

export default function Regulations() {
  return (
    <div className="regulations" style={{ marginTop: "100px" }}>
      <h2> لائحة الشروط والاحكام الخاصة بالتسجيل في برنامج تدريبي</h2>
      <p style={{ lineHeight: "2" }}>
        عزيزي المتدرب، أهلا بانضمامكم الى قائمة متدربي ISA أكاديمي، الذين نفخر
        بخدمتهم طوال مدة دراستهم معنا. لتقديم أفضل خدمة تدريبية لكم، أرجو من
        سيادتكم الاطلاع على قائمة الشروط والأحكام الخاصة بالأكاديمية، والموافقة
        عليها قبل اتمام خطوات التسجيل، وذلك في إطار تنظيم الحقوق والواجبات
        الخاصة بالتسجيل في برامجنا التدريبية. <br />
        <br />
        <h4 style={{ marginTop: "50px" }}>الشروط والأحكام:</h4> <br />
        <ul>
          <li>
            1. لكل دورة حد ادنى من عدد المشاركين لتستطيع الأكاديمية بدء البرنامج
            التدريبي فوراً، وفور الوصول للحد الأدنى يتم تحديد تاريخ بدء الدورة،
            ويمكن تسجيل حجوزات جديدة حتى اكتمال العدد أو تاريخ البدء المحدد
            أيهما أقرب.
          </li>
          <li>
            2. التسجيل في أي من برامجنا التدريبية يعني التزام الأكاديمية بتقديم
            الخدمات التدريبية الواجبة لكم، وتوفير المادة التدريبية سواء مطبوعة
            أو الكترونية – حسب طبيعة البرنامج التدريبي – وتنظيم المحاضرات في
            المواعيد المعلنة، والتي يتم اعلامكم بها سواء وقت التسجيل أو بعده.
          </li>
          <li>
            3. أي تعديل في مواعيد البرامج التدريبية يتم بالتنسيق مع المتدربين،
            وتتحرى فيه الأكاديمية - قدر المستطاع - المواعيد الملائمة للمتدربين.
          </li>
          <li>
            4. إذا لم يتم تحديد تاريخ لبدء الدورة خلال مدة أقصاها 30 يوم من
            تاريخ السداد، يحق للمتدرب أن يسترد مدفوعاته كاملة أو الانتظار حتى
            اكتمال العدد، أو تحويل تلك الرسوم إلى دورة تدريبية أخرى، وتتم عملية
            الاسترداد بنفس طريقة الدفع.
          </li>
          <li>
            5. إذا تم تحديد تاريخ بداية البرنامج التدريبي، فإنه لا يحق للمتدرب
            حينها وتحت أي ظرف الانسحاب من البرنامج التدريبي أو استرداد أي رسوم
            مدفوعة.
          </li>
          <li>
            6. في الدورات التي تتم فيها الدراسة أون لاين عبر الانترنت، يكون
            تسجيل الحضور من خلال التواجد الفعلي في وقت المحاضرة، كذلك يجب على
            المتدرب التفاعل مع المحاضر كشرط أساسي لإثبات الحضور، ولا يُمثل
            مشاهدة التسجيل الخاص بالمحاضرة كحضور في هذه المحاضرة أبداً، وبالتالي
            لا يحق للمتدرب احتساب مشاهدته لأي محاضرة مسجلة كحضور فيها.
          </li>
          <li>
            7. لضمان الحضور والحصول على الشهادة التدريبية، يجب على كل متدرب
            استكمال ملفه بالأكاديمي بصورة واضحة من وثيقة إثبات الشخصية، حيث لن
            يتمكن المتدرب من حضور المحاضرات إلا بعد استيفاء هذا الشرط.
          </li>
          <li>
            8. يتم استخراج الشهادة التدريبية في نهاية البرنامج التدريبي، بنفس
            الاسم المثبت في وثيقة إثبات الشخصية.
          </li>
          <li>
            9. لا يحق للمتدرب الحصول على شهادة الحضور في الدورة التدريبية في
            إحدى الحالات الآتية:
            <ul>
              <li>
                عدم استيفاء نسبة حضور 75% من إجمالي ساعات البرنامج التدريبي.
              </li>
              <li>عدم سداد كامل مصروفات البرنامج التدريبي.</li>
              <li>
                عدم حضور الورش العملية – إن كان البرنامج التدريبي يشترط ذلك.
              </li>
              <li>
                حصول المتدرب على تقييم سلبي من المُحاضر على مدار الكورس، يعكس
                عدم استيعاب المتدرب للمادة التدريبية بشكل يؤهله للحصول على
                الشهادة.
              </li>
              <li>
                عدم استيفاء البيانات أو عدم تقديم صورة واضحة من وثيقة إثبات
                الشخصية للمتدرب.
              </li>
            </ul>
          </li>
          <li>10. المصروفات المذكورة بالموقع لا تشمل مصاريف شحن الشهادات.</li>
          <li>
            11. الشهادات والاعتمادات الاجنبية التي نقدمها مع برامجنا التدريبية
            صادرة من مراكز تدريب أجنبية ولا تصدر عن جامعات أو كليات أجنبية.
          </li>
        </ul>
      </p>
    </div>
  );
}
