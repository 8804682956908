import React, { useEffect, useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Advertisement.scss";

export default function Advertisement() {
  const [news, setNews] = useState([]);

  useEffect(() => {
    const fetchNews = async () => {
      try {
        const response = await fetch(
          "https://admin.internationalskillsacademy.com/api/news/"
        );
        const data = await response.json();
        setNews(data.results);
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchNews();
  }, []);

  return (
    <div className="ad-slider">
      <Carousel indicators={false} controls={false}>
        {news.map((item) => (
          <Carousel.Item key={item.id}>
            <img
              className="d-block w-100"
              src={item.image || "placeholder-image.png"}
              alt={item.title}
            />
          </Carousel.Item>
        ))}
      </Carousel>
    </div>
  );
}
