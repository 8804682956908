import "./CustomerService.scss";
import { Link } from "react-router-dom";

export default function CustomerService() {
  return (
    <div
      className="custom-services"
      style={{ paddingTop: "220px", padding: "50px" }}
    >
      <div className="overlay"></div>
      <h2>الشكاوي و خدمة العملاء</h2>

      <p>
        إذا كان لديك أسئلة أو اقتراحات، يرجى ملء نموذج ملاحظاتك أو اقتراحاتك أو
        استفساراتك <Link to="/contact">بالضغط هنا . </Link>
        <br />
        يمكنكم مراسلتنا على العنوان التالي:
        <strong>
          المنشية، خلف بناء المحافظة/ طرطوس، الجمهورية العربية السورية.
        </strong>
        <br /> ويمكنكم الاتصال بنا عبر البريد الإلكتروني:{" "}
        <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
        <br />
        ويمكنكم الاتصال بنا عن طريق:
      </p>

      <ul>
        <li>
          {" "}
          الهاتف: <a href="tel:+963988954443">+ 963 988 954 443</a>
        </li>
        <li>
          {" "}
          البريد الإلكتروني:{" "}
          <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a>
        </li>
      </ul>
    </div>
  );
}
