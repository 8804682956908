import { Link } from "react-router-dom";
import "./Privacy.scss";

export default function Privacy() {
  return (
    <div className="privacy" style={{ paddingTop: "220px", padding: "50px" }}>
      <div className="overlay"></div>
      <h4 style={{ marginTop: "unset" }}>سياسة الخصوصية</h4>
      <p>
        يأخذ موقع أكاديمية ISA للتدريب خصوصيتك على محمل الجد.. لذا يرجى قراءة ما
        يلي لمعرفة المزيد حول سياسة الخصوصية التي يتبعها الموقع.
      </p>

      <h4>كيف يستخدم أكاديمية ISA للتدريب معلوماتك الشخصية</h4>
      <p>
        يوضح المعلومات التالية كيفية تعامل موقع أكاديمية ISA للتدريب مع
        المعلومات الشخصية التي يجمعها و /أو يتلقاها، بما في ذلك المعلومات
        المتعلقة باستخدامك السابق لأي من خدمات ومحتويات أكاديمية ISA للتدريب.
        <br />
        المعلومات الشخصية هي معلومات عنك تحدد هويتك مثل اسمك وعنوانك وعنوان
        البريد الإلكتروني، أو رقم الهاتف.
      </p>

      <h4>كيفية تفعيل سياسة الخصوصية على أكاديمية ISA للتدريب</h4>
      <p>
        هذه السياسة لا تنطبق على تعاملات أو ممارسات مواقع أو قنوات لا يملكها أو
        يسيطر عليها أكاديمية ISA للتدريب، وكذا على الموظفين أو الأشخاص الذين لا
        يعملون في أكاديمية ISA للتدريب أو تحت إدارته.
        <br />
        بالإضافة إلى ذلك، وفي حالة انضمام أي مؤسسات أو جهات أو مواقع لأكاديمية
        ISA للتدريب، ستطبق سياسة الخصوصية هذه على هذه المؤسسات والجهات والمواقع.
      </p>

      <h4>حماية حقوق الطبع والملكية الفكرية</h4>
      <p>
        أكاديمية ISA للتدريب يشارك في حماية حقوق الطبع والملكية الفكرية سواء
        لمؤسسات أو لأفراد وذلك بعدم نشر أي محتوى أو خدمة إلا مع ذكر اسم المصدر
        أو بالرجوع لصاحب حقوق الطبع أو الملكية لهذا المحتوى أو الخدمة.
        <br />
        وكذا عدم نشر أي محتوى مقرصن على الشبكة العنكبوتية.
      </p>

      <h4>جمع المعلومات واستخدامها</h4>
      <p>
        يقوم موقع أكاديمية ISA للتدريب بجمع المعلومات الشخصية عندما تقوم
        بالتسجيل لإنشاء حساب على أكاديمية ISA للتدريب، وعند التسجيل في بعض
        الخدمات.
        <br />
        المعلومات التي نطلبها تشمل:
      </p>
      <ul>
        <li>اسمك</li>
        <li>عنوان البريد الإلكتروني</li>
        <li>تاريخ الميلاد</li>
        <li>الجنس</li>
        <li>الرمز البريدي</li>
        <li>الوظيفة</li>
        <li>محل الإقامة</li>
      </ul>
      <p>
        بالنسبة لبعض الخدمات قد نطلب منك معلومات إضافية تشمل الأصول الخاصة بك،
        طريقة إتصالك بالإنترنت، هواياتك، مفضلاتك، ونبذة شخصية عنك.
        <br />
        عندما تقوم بالتسجيل مع أكاديمية ISA للتدريب، فإنك غير مجهول بالنسبة لنا.
        يجمع الموقع معلومات عن معاملاتك معنا ومع شركائنا التجاريين.
      </p>

      <h4>أطفال</h4>
      <p>غير مسموح لمن هم دون سن الـعاشرة بالتسجيل على أكاديمية ISA للتدريب.</p>

      <h4>مشاركة وكشف المعلومات</h4>
      <p>
        لا يقوم أكاديمية ISA للتدريب بإيجار أو بيع أو مشاركة معلوماتك الشخصية مع
        أشخاص آخرين أو شركات غير تابعة إلا لتوفير محتويات أو الخدمات التي
        طلبتها.
        <br />
        وفي ظل الظروف التالية:
      </p>
      <ul>
        <li>
          توفير المعلومات لشركاء موثوقين يعملون نيابة عن أو مع أكاديمية ISA
          للتدريب بموجب الاتفاقات السرية.
        </li>
        <li>
          الاستجابة لأوامر الاستدعاء أو أوامر المحكمة أو الإجراءات القانونية.
        </li>
        <li>التحقيق أو منع أو اتخاذ إجراءات تتعلق بأنشطة غير مشروعة.</li>
        <li>خلال نقل معلومات عنك إذا تم نقل ملكية أكاديمية ISA للتدريب.</li>
        <li>
          أكاديمية ISA للتدريب لا يقدم أي معلومات شخصية للمعلن عند التفاعل مع
          الإعلانات المستهدفة.
        </li>
      </ul>

      <h4>الكوكيز</h4>
      <p>
        أكاديمية ISA للتدريب قد يخزن ويحصل على الكوكيز من جهاز الكمبيوتر الخاص
        بك.
      </p>

      <h4>قدرتك على تعديل وحذف معلومات حسابك وتفضيلاتك</h4>
      <p>
        يمكنك تعديل معلومات حساب أكاديمية ISA للتدريب الخاص بك، بما في ذلك
        تفضيلاتك التسويقية في أي وقت.
        <br />
        رغم ذلك، يحتفظ أكاديمية ISA بحقه في إرسال بعض الأخبار أو العروض المتعلقة
        بخدماته.
      </p>
      <p>
        لا يمكنك حذف الحساب الخاص بك على أكاديمية ISA. يتم حذف الحسابات التي لم
        يتم استخدامها لفترة طويلة تلقائياً.
      </p>

      <h4>السرية والأمان</h4>
      <p>
        يحظر أكاديمية ISA للتدريب الحصول على معلومات شخصية عنك إلا للموظفين
        الذين نعتقد أنهم بحاجة إليها لتوفير الخدمات لك.
        <br />
        لدينا الضمانات المادية والإلكترونية والإجرائية لحماية المعلومات الشخصية.
      </p>

      <h4>التعديلات قد تطرأ على سياسة الخصوصية</h4>
      <p>
        قد يقوم موقع أكاديمية ISA للتدريب بتحديث هذه السياسة. سيتم إعلامك
        بالتغييرات الكبيرة عن طريق الإشعار على البريد الإلكتروني أو وضع إشعار
        بارز على الموقع.
      </p>

      <h4>أسئلة و اقتراحات</h4>
      <p>
        إذا كان لديك أسئلة أو اقتراحات، يرجى ملء نموذج ملاحظاتك أو اقتراحاتك أو
        استفساراتك <Link to="/contact">بالضغط هنا . </Link>
        <br />
        يمكنك مراسلتنا على العنوان التالي: المنشية، خلف بناء المحافظة/ طرطوس،
        الجمهورية العربية السورية.
        <br />
        يمكنك الاتصال بنا عبر البريد الإلكتروني:{" "}
        <a href="mailto:isaacademy023@gmail.com">isaacademy023@gmail.com</a> أو
        عن طريق الهاتف:
        <a href="tel:+963988954443">+ 963 988 954 443</a>
      </p>
    </div>
  );
}
