import React, { useEffect, useState } from "react";
import "./LiveCourses.scss";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import useAuth from "../../hooks/useAuth";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";

const LiveCourses = () => {
  const [lessons, setLessons] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showCouponModal, setShowCouponModal] = useState(false);
  const [couponCode, setCouponCode] = useState("");
  const [selectedLesson, setSelectedLesson] = useState(null);
  const [showLoginAlert, setShowLoginAlert] = useState(false);
  const [couponError, setCouponError] = useState(null);
  const { user } = useAuth();
  const history = useHistory();

  useEffect(() => {
    const fetchLessons = async () => {
      try {
        const response = await axios.get(
          `https://admin.internationalskillsacademy.com/api/live_courses/`
        );
        const lessonsData = response.data.results;

        setLessons(lessonsData);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchLessons();
  }, []);

  const handleCouponSubmit = async (lesson) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    if (!couponCode.trim()) {
      setCouponError("لا يجب أن يكون حقل الكوبون فارغًا");
      return;
    } else {
      setCouponError(null);
    }

    const couponRegex = /^[a-zA-Z0-9-]{36}$/;

    if (!couponRegex.test(couponCode)) {
      setCouponError("الكوبون غير صالح.");
      return;
    }

    const couponData = { coupon_code: couponCode };

    try {
      const response = await axios.post(
        `https://admin.internationalskillsacademy.com/api/live_courses/${lesson.id}/enroll/`,
        couponData,
        {
          headers: {
            Authorization: `token ${user.token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response) {
        const successMessage = `تم إدخال الكوبون بنجاح لكورس ${lesson.title}`;
        lesson.couponMessage = successMessage;

        setCouponCode("");
        setShowCouponModal(false);
        history.push(`/live-course/${lesson.slug}`);
      } else {
        setCouponError("الكوبون غير صالح.");
      }
    } catch (error) {
      console.error("هناك مشكلة في تطبيق الكوبون:", error);
      setCouponError("هناك مشكلة في تطبيق الكوبون.");
    }
  };

  const handleLessonClick = (lesson) => {
    if (!user) {
      setShowLoginAlert(true);
      return;
    }

    if (lesson.is_enrolled) {
      history.push(`/live-course/${lesson.slug}`);
    } else if (lesson.price > 0) {
      setSelectedLesson(lesson);
      setShowCouponModal(true);
    } else {
      history.push(`/live-course/${lesson.slug}`);
    }
  };

  if (loading) return <div>جاري تحميل الدروس...</div>;
  if (error) return <div>حدث خطأ: {error}</div>;

  return (
    <div className="course-page container">
      <h2 className="text-center my-4">المحاضرات</h2>
      {showLoginAlert && (
        <Alert
          variant="warning"
          onClose={() => setShowLoginAlert(false)}
          dismissible
        >
          يجب عليك تسجيل الدخول لمشاهدة المحاضرات.
        </Alert>
      )}
      {couponError && (
        <Alert
          variant="danger"
          onClose={() => setCouponError(null)}
          dismissible
          style={{
            zIndex: "1000000",
            top: 0,
            position: "fixed",
            width: "100%",
          }}
        >
          {couponError}
        </Alert>
      )}
      <div className="row">
        {lessons.map((lesson) => (
          <div className="col-md-4 mb-4" key={lesson.id}>
            <div className="card lesson-card">
              <img
                src={lesson.image}
                alt={lesson.title}
                className="card-img-top"
              />
              <div className="card-body">
                <h5 className="card-title">{lesson.title}</h5>
                <p className="card-text">ملخص: {lesson.summary}</p>
                <p className="card-text">المُدرب: {lesson.author.username}</p>
                <p className="card-text">التاريخ: {lesson.date}</p>

                {lesson.price > 0 ? (
                  <p className="card-text text-danger">
                    مدفوع: {lesson.price} SP
                  </p>
                ) : (
                  <p className="card-text text-success">مجاني</p>
                )}

                <button
                  style={{
                    backgroundColor: "#2f2d52",
                    color: "white",
                    border: "none",
                  }}
                  className="btn btn-success"
                  onClick={() => handleLessonClick(lesson)}
                >
                  شاهد المحاضرة
                </button>
                {lesson.couponMessage && (
                  <div className="alert alert-success mt-2" role="alert">
                    {lesson.couponMessage}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      {selectedLesson && (
        <Modal
          style={{
            position: "absolute",
            top: "100px",
            left: 0,
            right: 0,
          }}
          show={showCouponModal}
          onHide={() => setShowCouponModal(false)}
        >
          <Modal.Header closeButton>
            <Modal.Title>أدخل الكوبون</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <input
              type="text"
              placeholder="أدخل كود الكوبون"
              value={couponCode}
              onChange={(e) => setCouponCode(e.target.value)}
              className="form-control"
            />
          </Modal.Body>
          <Modal.Footer>
            <Button variant="danger" onClick={() => setShowCouponModal(false)}>
              إلغاء
            </Button>
            <Button
              style={{
                backgroundColor: "#2f2d52",
                border: "1px solid #2f2d52",
              }}
              onClick={() => handleCouponSubmit(selectedLesson)}
            >
              تأكيد
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
};

export default LiveCourses;
